export default function Pagination({ page, setPage, maxPage, nextDisabled }) {
  const onChange = (e) => {
    const value = e.target.value;
    if (parseInt(value) <= 0 || parseInt(value) > maxPage || value === "") {
      return;
    }
    setPage(parseInt(value));
  };

  const onFocus = (e) => e.target.select();

  return (
    <div className="m-auto text-center p-4">
      <button
        className="btn btn-light m-1"
        disabled={page === 1 && true}
        onClick={() => setPage(page - 1)}
      >
        Previous
      </button>
      <input
        className="form-control d-inline w-input-page"
        onChange={onChange}
        type="number"
        value={page}
        onFocus={onFocus}
      ></input>
      <button
        className="btn btn-light m-1"
        disabled={nextDisabled}
        onClick={() => {
          setPage(page + 1);
        }}
      >
        Next
      </button>
      <div className="fs-6 text-center text-primary">
        max page
        <span> {maxPage}</span>
      </div>
    </div>
  );
}
