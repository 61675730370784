import { useCallback, useEffect, useState } from "react";
import { intervalToDuration, isBefore } from "date-fns";

import examService from "../../services/exam.service";

// import Score from "./Score";

import "./LiveExam.css";

export default function LiveExam({ questions, futureDate, id }) {
  const [answerQuestions, setAnswerQuestions] = useState({});
  const { minutes, seconds, isTimeUp } = useTicker(futureDate);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [showScore, setShowScore] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(null);

  const clickBack = () => {
    window.location.reload();
  };

  const getStudentSubmit = useCallback(() => {
    const questions = [];
    for (const [key, value] of Object.entries(answerQuestions)) {
      questions.push({ id: key, answer_id: value });
    }
    return questions;
  }, [answerQuestions]);

  const onChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setAnswerQuestions({ ...answerQuestions, [name]: value });
  };

  const onChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const getSubmit = useCallback(() => {
    return {
      name: inputs?.name,
      invoice_id: inputs?.invoice_id,
      questions: getStudentSubmit(),
    };
  }, [getStudentSubmit, inputs?.name, inputs?.invoice_id]);

  const sendSubmit = useCallback(async () => {
    setDisabled(true);
    // setLoading(true);
    const data = getSubmit();
    const res = await examService.submit(id, data);
    if (res.status === 200) {
      setScore(res.data);
      setDisabled(false);
      // console.log(res.data);
    } else {
      if (res.response.data.ERROR !== "Exam not exist") {
        setError(true);
        console.log(res.response.data.ERROR);
        setDisabled(false);
      } else {
        console.log(res.response.data.ERROR);
      }
    }
  }, [getSubmit, id]);

  const onSubmit = (e) => {
    e.preventDefault();
    sendSubmit();
  };

  function useTicker(futureDate) {
    const [now, setNow] = useState(new Date());

    useEffect(() => {
      const interval = setInterval(() => {
        setNow(new Date());
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [futureDate]);

    const isTimeUp = isBefore(futureDate, now);

    if (isTimeUp) {
      return { minutes: 0, seconds: 0, isTimeUp };
    }

    let { minutes, seconds } = intervalToDuration({
      start: now,
      end: futureDate,
    });

    return { minutes, seconds, isTimeUp };
  }

  useEffect(() => {
    if (score !== null) {
      setShowScore(true);
    }
    if (isTimeUp) {
      const EndTime = async () => {
        await sendSubmit();
        setShowScore(true);
      };
      EndTime();
    }
  }, [isTimeUp, sendSubmit, score]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  return (
    <div className="live-exam container-fluid">
      {showScore ? (
        <div className="show-score">
          {error ? (
            <div>
              <div className="text-center text-danger fs-3 p-2">
                something is wrong
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className="text-center text-success fs-3 p-2">
                Submit Successful!
              </div>
              <div className="text-center text-muted fs-3 p-2">
                your answers delivered, contact our exams coordinator
                <br />
                for your score.
              </div>
              <button className="btn btn-light" onClick={clickBack}>
                Back
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          {disabled ? (
            <div className="show-score">
              <div className="fs-3">Loading...</div>
            </div>
          ) : (
            <form onSubmit={onSubmit} className="live-container">
              <div className="in-container">
                <span className="timer">
                  <span>
                    {minutes} : {seconds}
                  </span>
                </span>
                <label>Name</label>
                <input
                  className="input-fd"
                  type="text"
                  name="name"
                  disabled={disabled}
                  onChange={onChangeInputs}
                  required
                />
                <label>Invoice ID</label>
                <input
                  className="input-fd"
                  type="text"
                  name="invoice_id"
                  disabled={disabled}
                  onChange={onChangeInputs}
                />
                {questions.map((question) => (
                  <div className="live-question">
                    <h4>{question.content}</h4>
                    {question.answers.map((answer) => (
                      <>
                        <input
                          type="radio"
                          name={question.id}
                          value={answer.id}
                          required
                          onChange={onChange}
                          key={answer.id}
                          disabled={disabled}
                        />
                        <label htmlFor="male">{answer.content}</label>
                        <br />
                      </>
                    ))}
                  </div>
                ))}

                <button type="submit" disabled={disabled}>
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
}
