import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import answersService from "../../services/answers.service";
import questionsService from "../../services/questions.service";
// import "./desc.css";

export default function AddAnswer() {
  const [addAnswers, setAddAnswers] = useState({
    content: "",
    isCorect: "",
    questions_id: "",
  });
  const [questions, setQuestions] = useState([]);
  const [selected, setSelected] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getSelcted = (id) => {
    let newselected = selected;
    newselected = id;
    setSelected(newselected);
  };

  function getNewAnswers(e) {
    let newAddanswers = { ...addAnswers };
    newAddanswers[e.target.name] = e.target.value;
    setAddAnswers(newAddanswers);
    getSelcted(e.target.value);
  }

  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    const { data } = await answersService.createOne(addAnswers);
    if (data) {
      setError(false);
      navigate("/answers/showanswers");
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const getAllQuestions = async (callback) => {
    let { data } = await questionsService.AllData();
    setLoading(true);
    if (data) {
      setError(false);
      callback(data.value);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllQuestions(setQuestions);
  }, []);

  return (
    <div className="courses">
      <aside>
        <header>
          <form onSubmit={submitForm} className="add-new-course">
            <label>Answer Content </label>
            <input onChange={getNewAnswers} type="text" name="content" />
            <label>Answer Value</label>
            <select
              className="w-100 p-2"
              onChange={getNewAnswers}
              name="isCorect"
            >
              <option value="Choose Answer Value:">Choose Answer Value:</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="12">12</option>
            </select>
            <label>Questions ID</label>
            <select
              onChange={getNewAnswers}
              name="questions_id"
              className="w-100 p-2"
            >
              <option value="Select Questions ID:">Select Questions ID:</option>
              {questions?.map((question, i) => (
                <option key={i} value={question.id}>
                  {question.id}
                </option>
              ))}
            </select>
            <button type="submit" className="new">
              {loading ? "Loading..." : " Add New Answer"}
            </button>
          </form>
        </header>
      </aside>
      <div className="text-center p-3">
        {error ? <p className="fs-5 text-danger">something is wrong</p> : ""}
      </div>
    </div>
  );
}
