import axios from "axios";

import authHeader from "./auth-header";

const API_URL = "https://americanacademyeg.co/api/v1/";
// const API_URL = "http://localhost:5000/api/v1/";

class StudentsService {
  async getAll(page) {
    try {
      const res = await axios.get(`${API_URL + "students"}`, {
        headers: authHeader(),
        params: { page },
      });
      return res;
    } catch (error) {
      return error;
    }
  }
}

export default new StudentsService();
