import { NavLink, Outlet } from "react-router-dom";
import "./courses.css";

export default function Courses() {
  return (
    <div>
      <div className="courses-nav">
        <NavLink to="/courses/showcourses">Show Courses </NavLink>
        <NavLink to="/courses/addcourses">Add Courses </NavLink>
      </div>
      <Outlet />
    </div>
  );
}
// import { useEffect, useState } from "react";

// import coursesService from "../services/courses.service";
// import questionsService from "../services/questions.service";
// import answersService from "../services/answers.service";
// import "./courses.css";

// export default function Courses() {
//   const [courses, setCourses] = useState([]);
//   const [questions, setQuestions] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState(null);
//   const [selectedQuestion, setSelectedQuestions] = useState(null);
//   const [answers, setAnswers] = useState(null);

//   function select(id) {
//     setSelectedCourse(id);
//   }

//   function selectQuestion(id) {
//     setSelectedQuestions(id);
//   }

//   const getAllQuestions = async (courseId) => {
//     if (!courseId) {
//       return;
//     }
//     const res = await questionsService.getAll(courseId);
//     setQuestions(res.data?.value);
//   };

//   const getAllAnswers = async (questionId) => {
//     if (!questionId) {
//       return;
//     }
//     const res = await answersService.getAll(questionId);
//     setAnswers(res.data?.value);
//   };

//   function AddNewCourse() {
//     return (
//       <form className="add-new-course">
//         <label>Course Name </label>
//         <input type="text" />
//         <button className="new">Add New Courses</button>
//       </form>
//     );
//   }

//   useEffect(() => {
//     const getAllCourses = async () => {
//       const res = await coursesService.getAll();
//       setCourses(res.data.value);
//       setSelectedCourse(res.data.value[0].id);
//     };

//     getAllCourses();
//   }, []);

//   useEffect(() => {
//     getAllQuestions(selectedCourse);
//   }, [selectedCourse]);

//   useEffect(() => {
//     getAllAnswers(selectedQuestion);
//   }, [selectedQuestion]);

//   return (
//     <div className="courses">
//       <aside>
//         <header>
//           <AddNewCourse />
//         </header>
//         {courses?.map((course) => (
//           <div
//             className={`course ${
//               course.id === selectedCourse ? "selected" : ""
//             }`}
//             onClick={() => select(course.id)}
//             key={course.id}
//           >
//             {course.name}
//           </div>
//         ))}
//       </aside>
//       <main>
//         <header>
//           <div className="new">Add New Question</div>
//         </header>
//         <ol>
//           {questions?.map((question) => (
//             <li onClick={() => selectQuestion(question.id)} key={question.id}>
//               <div
//                 className={`question ${
//                   question.id === selectedQuestion ? "selected-question" : ""
//                 }`}
//               >
//                 {question.content}
//               </div>
//               {question.id === selectedQuestion && (
//                 <div className="answers">
//                   {answers?.map((answer) => (
//                     <div
//                       className={answer.isCorect ? "is-corect" : ""}
//                       key={answer.id}
//                     >
//                       {answer.content}
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </li>
//           ))}
//         </ol>
//       </main>
//     </div>
//   );
// }
