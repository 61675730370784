import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import coursesService from "../../services/courses.service";

function UpdateCourse() {
  const [updateCourse, setUpdateCourse] = useState({
    name: "",
  });
  const [course, setCourse] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  function getUpdateCourse(e) {
    let newUpdateCourse = { ...updateCourse };
    newUpdateCourse[e.target.name] = e.target.value;
    setUpdateCourse(newUpdateCourse);
  }

  const getOneCourseByID = async () => {
    let { data } = await coursesService.getOneByID(id);
    setLoading(true);
    if (data) {
      setCourse(data.value);
      setLoading(false);
    }
    setLoading(false);
  };

  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    const { data } = await coursesService.updateOne(updateCourse, id);
    if (data) {
      setError(false);
      navigate("/courses/showcourses");
    } else {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    getOneCourseByID();
  });

  return (
    <div className="courses">
      <aside>
        <header>
          <form onSubmit={submitForm} className="add-new-course">
            <label>Course Name </label>
            <input onChange={getUpdateCourse} type="text" name="name" />
            {loading ? (
              <p className="p-2 fs-5 text-start text-primary">Loading...</p>
            ) : (
              <div>
                {course?.map((pr, i) => (
                  <p key={i} className="p-2 fs-5 text-start text-primary">
                    previous course name : <br />
                    <span className="text-dark">{pr.name}</span>
                  </p>
                ))}
              </div>
            )}
            <button type="submit" className="new">
              {loading ? "Loading..." : "Update Course"}
            </button>
          </form>
        </header>
      </aside>
      <div className="text-center p-3">
        {error ? <p className="fs-5 text-danger">something is wrong</p> : ""}
      </div>
    </div>
  );
}

export default UpdateCourse;
