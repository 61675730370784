import axios from "axios";

const API_URL = "https://americanacademyeg.co/api/v1/";
// const API_URL = "http://localhost:5000/api/v1/";

class AuthService {
  url = API_URL + "session";
  async login({ username, password }) {
    try {
      const res = await axios.post(this.url, {
        username,
        password,
      });

      if (res.data.value[0].token) {
        localStorage.setItem("user", JSON.stringify(res.data));
      }

      return res;
    } catch (error) {
      return error;
    }
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
