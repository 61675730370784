import axios from "axios";

import authHeader from "./auth-header";

const API_URL = "https://americanacademyeg.co/api/v1/";
// const API_URL = "http://localhost:5000/api/v1/";

class QuestionsService {
  async getAll(page) {
    try {
      const res = await axios.get(`${API_URL + "questions"}`, {
        headers: authHeader(),
        params: { page },
      });
      // console.log(res);
      return res;
    } catch (error) {
      return error;
    }
  }

  async AllData() {
    try {
      const res = await axios.get(`${API_URL + "allquestions"}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async createOne(question) {
    try {
      const res = await axios.post(`${API_URL + "question"}`, question, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async getOneByID(id) {
    try {
      const res = await axios.get(`${API_URL}/question/${id}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async deleteOne(id) {
    try {
      const res = await axios.delete(`${API_URL}/question/${id}`, {
        headers: authHeader(),
      });
      // console.log(res);
      return res;
    } catch (error) {
      // console.log(error.response.data);
      return error;
    }
  }

  async updateOne(question, id) {
    try {
      const res = await axios.put(`${API_URL}/question/${id}`, question, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }
}

export default new QuestionsService();
