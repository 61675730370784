import { Navigate, Route, Routes } from "react-router-dom";

import Courses from "../Courses";
import Home from "../Home";
import Students from "../Students";

import Navbar from "./Navbar";

import "./Dashboard.css";
import AddCourses from "../Courses/AddCourses";
import ShowCourses from "../Courses/ShowCourses";
import Questions from "../Questions";
import Answers from "../Answers";
import AddAnswer from "../Answers/AddAnswer";
import ShowAnswers from "../Answers/ShowAnswers";
import AddQuestion from "../Questions/AddQuestion";
import ShowQuestions from "../Questions/ShowQuestions";
import AnswerDetails from "../Answers/AnswerDetails";
import QuestionDetails from "../Questions/QuestionDetails";
import CourseDetails from "../Courses/CourseDetails";
import DeleteAnswer from "../Answers/DeleteAnswer";
import UpdateAnswer from "../Answers/UpdateAnswer";
import DeleteQuestion from "../Questions/DeleteQuestion";
import UpdateQuestion from "../Questions/UpdateQuestion";
import DeleteCourse from "../Courses/DeleteCourse";
import UpdateCourse from "../Courses/UpdateCourse";

export default function Dashboard() {
  return (
    <div className="container-fluid">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/students" element={<Students />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
        <Route path="/courses" element={<Courses />}>
          <Route path="addcourses" element={<AddCourses />} />
          <Route path="showcourses" element={<ShowCourses />} />
          <Route path="showdetails/:id" element={<CourseDetails />} />
          <Route path="deletecourse/:id" element={<DeleteCourse />} />
          <Route path="updatecourse/:id" element={<UpdateCourse />} />
        </Route>
        <Route path="/questions" element={<Questions />}>
          <Route path="addquestions" element={<AddQuestion />} />
          <Route path="showquestions" element={<ShowQuestions />} />
          <Route path="showdetails/:id" element={<QuestionDetails />} />
          <Route path="deletequestion/:id" element={<DeleteQuestion />} />
          <Route path="updatequestion/:id" element={<UpdateQuestion />} />
        </Route>
        <Route path="/answers" element={<Answers />}>
          <Route path="addanswers" element={<AddAnswer />} />
          <Route path="showanswers" element={<ShowAnswers />} />
          <Route path="showdetails/:id" element={<AnswerDetails />} />
          <Route path="deleteanswer/:id" element={<DeleteAnswer />} />
          <Route path="updateanswer/:id" element={<UpdateAnswer />} />
        </Route>
      </Routes>
    </div>
  );
}
