import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import coursesService from "../../services/courses.service";
import questionsService from "../../services/questions.service";

function UpdateQuestion() {
  const [updateQuestion, setUpdateQuestion] = useState({
    content: "",
    course_id: "",
  });
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState("");
  const [question, setQuestion] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const getSelcted = (id) => {
    let newselected = selected;
    newselected = id;
    setSelected(newselected);
  };

  function getUpdateQuestion(e) {
    let newUpdateQuestion = { ...updateQuestion };
    newUpdateQuestion[e.target.name] = e.target.value;
    setUpdateQuestion(newUpdateQuestion);
    getSelcted(e.target.value);
  }

  const getOneQuestionByID = async () => {
    let { data } = await questionsService.getOneByID(id);
    setLoading(true);
    if (data) {
      setLoading(false);
      setQuestion(data.value);
    }
    setLoading(false);
  };

  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    const { data } = await questionsService.updateOne(updateQuestion, id);
    if (data) {
      setError(false);
      navigate("/questions/showquestions");
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const getAllCourses = async (callback) => {
    let { data } = await coursesService.AllData();
    setLoading(true);
    if (data) {
      setError(false);
      callback(data.value);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOneQuestionByID();
  });

  useEffect(() => {
    getAllCourses(setCourses);
  }, []);

  return (
    <div className="courses">
      <aside>
        <header>
          <form onSubmit={submitForm} className="add-new-course">
            <label>Question Content </label>
            <input onChange={getUpdateQuestion} type="text" name="content" />
            {loading ? (
              <p className="p-2 fs-5 text-start text-primary">Loading...</p>
            ) : (
              <div>
                {question?.map((ques, i) => (
                  <p key={i} className="p-2 fs-5 text-start text-primary">
                    previous question content : <br />
                    <span className="text-dark">{ques.content}</span>
                  </p>
                ))}
              </div>
            )}
            <label>course_id</label>
            <select
              onChange={getUpdateQuestion}
              name="course_id"
              className="w-100 p-2"
            >
              <option value="Select Course:">Select Course:</option>
              {courses?.map((course, i) => (
                <option key={i} value={course.id}>
                  {course.name} with ID: {course.id}
                </option>
              ))}
            </select>
            {loading ? (
              <p className="p-2 fs-5 text-start text-primary">Loading...</p>
            ) : (
              <div>
                {question?.map((ques, i) => (
                  <p key={i} className="p-2 fs-5 text-start text-primary">
                    previous question course_id : <br />
                    <span className="text-dark">{ques.course_id}</span>
                  </p>
                ))}
              </div>
            )}
            <button type="submit" className="new">
              {loading ? "Loading..." : "Update Question"}
            </button>
          </form>
        </header>
      </aside>
      <div className="text-center p-3">
        {error ? <p className="fs-5 text-danger">something is wrong</p> : ""}
      </div>
    </div>
  );
}

export default UpdateQuestion;
