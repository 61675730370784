import { useEffect, useState } from "react";
import answersService from "../../services/answers.service";
import questionsService from "../../services/questions.service";
import { useNavigate } from "react-router-dom";
import "./desc.css";
import Pagination from "../../Pagination";

export default function ShowAnswers() {
  const [allAnswers, setAllAnswers] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [maxPage, setMaxPage] = useState(null);
  const [page, setPage] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const navigate = useNavigate();

  const getSelcted = (id) => {
    let newselected = selected;
    newselected = id;
    setSelected(newselected);
  };

  function Data() {
    const result = allAnswers.filter((answer) => answer.questions_id == selected);
    // console.log(result);
    return (
      <div>
        <div>
          {result?.map((answer) => (
            <div className="course" key={answer.id}>
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-md-7">
                    <h5>{answer.content}</h5>
                  </div>
                  <div
                    className="
                    col-md-5 d-flex
                    flex-column
                    justify-content-center"
                  >
                    <div className="row">
                      <div className="col-md-6 my-1">
                        <button
                          onClick={() => {
                            navigate(`/answers/showdetails/${answer.id}`);
                          }}
                          className="btn w-100 btn-primary"
                        >
                          Show Details
                        </button>
                      </div>
                      <div className="col-md-3 my-1">
                        <button
                          onClick={() => {
                            navigate(`/answers/updateanswer/${answer.id}`);
                          }}
                          className="btn w-100 btn-warning"
                        >
                          Update
                        </button>
                      </div>
                      <div className="col-md-3 my-1">
                        <button
                          onClick={() => {
                            navigate(`/answers/deleteanswer/${answer.id}`);
                          }}
                          className="btn w-100 btn-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  useEffect(() => {
    const AllData = async () => {
      let { data } = await answersService.AllData();
      setLoading(true);
      if (data) {
        setError(false);
        setAllAnswers(data.value);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    const getAllAnswers = async () => {
      let { data } = await answersService.getAll(page);
      setLoading(true);
      if (data) {
        setError(false);
        setAnswers(data.value);
        setMaxPage(data.maxPage);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    const getAllQuestions = async () => {
      let { data } = await questionsService.AllData();
      setLoading(true);
      if (data) {
        setError(false);
        setQuestions(data.value);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    AllData();
    getAllAnswers();
    getAllQuestions();
  }, [page]);

  useEffect(() => {
    if (page === maxPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [maxPage, page]);

  return (
    <div className="courses">
      <div>
        {loading ? (
          <p className="text-center p-5 fs-5 text-info">Loading...</p>
        ) : (
          <div>
            {error ? (
              <p className="text-center p-5 fs-5 text-danger">
                something is wrong
              </p>
            ) : (
              <div>
                <div>
                  <label className="fs-4 fw-normal p-3" htmlFor="questions">
                    Choose a Question ID:{" "}
                  </label>
                  <select
                    onChange={(e) => getSelcted(e.target.value)}
                    className="w-25 p-3"
                    id="questions"
                  >
                    <option value="all">All</option>
                    {questions?.map((question, i) => (
                      <option key={i} value={question.id}>
                        {question.id}
                      </option>
                    ))}
                  </select>
                </div>
                <aside>
                  {selected ? (
                    <div>
                      {selected == "all" ? (
                        <div>
                          <div>
                            {answers?.map((answer) => (
                              <div className="course" key={answer.id}>
                                <div className="container">
                                  <div className="row justify-content-between">
                                    <div className="col-md-7">
                                      <h5>{answer.content}</h5>
                                    </div>
                                    <div
                                      className="
                                      col-md-5 d-flex
                                      flex-column
                                      justify-content-center"
                                    >
                                      <div className="row">
                                        <div className="col-md-6 my-1">
                                          <button
                                            onClick={() => {
                                              navigate(
                                                `/answers/showdetails/${answer.id}`,
                                              );
                                            }}
                                            className="btn w-100 btn-primary"
                                          >
                                            Show Details
                                          </button>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <button
                                            onClick={() => {
                                              navigate(
                                                `/answers/updateanswer/${answer.id}`,
                                              );
                                            }}
                                            className="btn w-100 btn-warning"
                                          >
                                            Update
                                          </button>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <button
                                            onClick={() => {
                                              navigate(
                                                `/answers/deleteanswer/${answer.id}`,
                                              );
                                            }}
                                            className="btn w-100 btn-danger"
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <Pagination
                            page={page}
                            setPage={setPage}
                            maxPage={maxPage}
                            nextDisabled={nextDisabled}
                          />
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <Data />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div>
                        {answers?.map((answer) => (
                          <div className="course" key={answer.id}>
                            <div className="container">
                              <div className="row justify-content-between">
                                <div className="col-md-7">
                                  <h5>{answer.content}</h5>
                                </div>
                                <div
                                  className="
                                  col-md-5 d-flex
                                  flex-column
                                  justify-content-center"
                                >
                                  <div className="row">
                                    <div className="col-md-6 my-1">
                                      <button
                                        onClick={() => {
                                          navigate(
                                            `/answers/showdetails/${answer.id}`,
                                          );
                                        }}
                                        className="btn w-100 btn-primary"
                                      >
                                        Show Details
                                      </button>
                                    </div>
                                    <div className="col-md-3 my-1">
                                      <button
                                        onClick={() => {
                                          navigate(
                                            `/answers/updateanswer/${answer.id}`,
                                          );
                                        }}
                                        className="btn w-100 btn-warning"
                                      >
                                        Update
                                      </button>
                                    </div>
                                    <div className="col-md-3 my-1">
                                      <button
                                        onClick={() => {
                                          navigate(
                                            `/answers/deleteanswer/${answer.id}`,
                                          );
                                        }}
                                        className="btn w-100 btn-danger"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Pagination
                        page={page}
                        setPage={setPage}
                        maxPage={maxPage}
                        nextDisabled={nextDisabled}
                      />
                    </div>
                  )}
                </aside>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
