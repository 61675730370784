import { NavLink, Outlet } from "react-router-dom";
import "./answers.css";

function Answers() {
  return (
    <div>
      <div className="courses-nav">
        <NavLink to="/answers/showanswers">Show Answers </NavLink>
        <NavLink to="/answers/addanswers">Add Answer </NavLink>
      </div>
      <Outlet />
    </div>
  );
}

export default Answers;
