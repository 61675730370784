import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import answersService from "../../services/answers.service";

function AnswerDetails() {
  const { id } = useParams();
  const [answer, setAnswer] = useState();
  // console.log(id);

  const getOneAnswerByID = async () => {
    let { data } = await answersService.getOneByID(id);
    // console.log(data);
    setAnswer(data.value);
  };

  useEffect(() => {
    getOneAnswerByID();
  });

  return (
    <div>
      {answer?.map((ans) => (
        <div className="container bg-light p-5" key={ans.id}>
          <h4 className="fw-bold fs-3 p-2">
            Answer ID: <span className="fw-light">{ans.id}</span>{" "}
          </h4>
          <h4 className="fw-bold fs-3 p-2">
            {" "}
            Answer Content: <br />{" "}
            <span className="fw-light">{ans.content}</span>{" "}
          </h4>
          <h4 className="fw-bold fs-3 p-2">
            question ID: <span className="fw-light">{ans.questions_id}</span>{" "}
          </h4>
          <h4 className="fw-bold fs-3 p-2">
              <span className="text-success">Answer Value: {ans.isCorect}</span>
          </h4>
        </div>
      ))}
    </div>
  );
}

export default AnswerDetails;
