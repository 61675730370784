import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import questionService from "../../services/questions.service";

function QuestionDetails() {
  const { id } = useParams();
  const [question, setQuestion] = useState();
  // console.log(id);

  const getOneQuestionByID = async () => {
    let { data } = await questionService.getOneByID(id);
    // console.log(data);
    setQuestion(data.value);
  };

  useEffect(() => {
    getOneQuestionByID();
  });

  return (
    <div>
      {question?.map((ques) => (
        <div className="container bg-light p-5" key={ques.id}>
          <h4 className="fw-bold fs-3 p-2">
            Question ID: <span className="fw-light">{ques.id}</span>{" "}
          </h4>
          <h4 className="fw-bold fs-3 p-2">
            {" "}
            Question Content: <br />{" "}
            <span className="fw-light">{ques.content}</span>{" "}
          </h4>
          <h4 className="fw-bold fs-3 p-2">
            Course ID: <span className="fw-light">{ques.course_id}</span>{" "}
          </h4>
        </div>
      ))}
    </div>
  );
}

export default QuestionDetails;
