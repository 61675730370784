import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import answersService from "../../services/answers.service";

function DeleteAnswer() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteOneRow = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const { data } = await answersService.deleteOne(id);
    if (data) {
      setError(false);
      navigate("/answers/showanswers");
    } else {
      setError(true);
    }
    setLoading(false);
  };
  return (
    <div className="container p-5 text-center">
      <h3 className="text-center">
        Are You Sure You Need To Delete This Answer?
      </h3>
      <div className="d-flex justify-content-center">
        <button
          onClick={(e) => deleteOneRow(e, `${id}`)}
          className="btn btn-danger m-3"
        >
          {loading ? "Loading..." : "Delete"}
        </button>
        <button
          onClick={() => {
            navigate("/answers/showanswers/");
          }}
          className="btn btn-info m-3"
        >
          Back
        </button>
      </div>
      <div className="text-center">
        {error ? <p className="fs-5 text-danger">something is wrong</p> : ""}
      </div>
    </div>
  );
}

export default DeleteAnswer;
