import axios from "axios";

import authHeader from "./auth-header";

const API_URL = "https://americanacademyeg.co/api/v1/";
// const API_URL = "http://localhost:5000/api/v1/";

class AnswersService {
  async getAll(page) {
    try {
      const res = await axios.get(`${API_URL + "answers"}`, {
        headers: authHeader(),
        params: { page },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async AllData() {
    try {
      const res = await axios.get(`${API_URL + "allanswers"}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async createOne(answer) {
    try {
      const res = await axios.post(`${API_URL + "answer"}`, answer, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async getOneByID(id) {
    try {
      const res = await axios.get(`${API_URL}/answer/${id}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async deleteOne(id) {
    try {
      const res = await axios.delete(`${API_URL}/answer/${id}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async updateOne(answer, id) {
    try {
      const res = await axios.put(`${API_URL}/answer/${id}`, answer, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }
}

export default new AnswersService();
