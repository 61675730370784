import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://americanacademyeg.co/api/v1/";
// const API_URL = "http://localhost:5000/api/v1/";

class ExamService {
  async get(exam_id) {
    const url = API_URL + "exam/" + exam_id;
    try {
      const res = await axios.get(url);
      return res;
    } catch (error) {
      return error;
    }
  }

  async submit(exam_id, data) {
    const url = API_URL + "exam/" + exam_id + "/submit";
    try {
      const res = await axios.post(url, data);
      return res;
    } catch (error) {
      return error;
    }
  }

  async generate(data) {
    const url = API_URL + "exam";
    try {
      const res = await axios.post(url, data, { headers: authHeader() });
      return res;
    } catch (error) {
      return error;
    }
  }
}

export default new ExamService();
