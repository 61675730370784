import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import authService from "../../services/auth.service";

import "./Navbar.css";

export default function Navbar() {
  const { setUser } = useContext(AuthContext);

  function logout() {
    authService.logout();
    setUser(null);
  }

  return (
    <nav className="navbar navbar-expand-lg bg-dark fs-5 rounded-start rounded-end m-3">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link text-light" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link text-light"
                to="/courses/showcourses"
              >
                Courses
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link text-light"
                to="/questions/showquestions"
              >
                Questions
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link text-light"
                to="/answers/showanswers"
              >
                Answers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-light" to="/students">
                Students
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-nav  ms-auto mb-2 mb-lg-0">
            <li className="nav-item bg-danger rounded-3 ">
              <NavLink
                onClick={logout}
                to="/login"
                replace={true}
                className="nav-link text-light "
              >
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa-solid fa-bars fs-1 text-success"></i>
        </button>
      </div>
    </nav>
  );
}

//<div className="navStyle">
//<NavLink to="/">Home</NavLink>
//<NavLink to="/courses/addcourses">Courses</NavLink>
//<NavLink to="/questions/addquestions">Questions</NavLink>
//<NavLink to="/answers/addanswers">Answers</NavLink>
//<NavLink to="/students">Students</NavLink>

//<NavLink onClick={logout} className="logout" to="/login" replace={true}>
//  Logout
//</NavLink>
//</div>
