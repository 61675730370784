import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import courseService from "../../services/courses.service";

function CourseDetails() {
  const { id } = useParams();
  const [course, setCourse] = useState();
  // console.log(id);

  const getOneCourseByID = async () => {
    let { data } = await courseService.getOneByID(id);
    // console.log(data);
    setCourse(data.value);
  };

  useEffect(() => {
    getOneCourseByID();
  });

  return (
    <div>
      {course?.map((c) => (
        <div className="container bg-light p-5" key={c.id}>
          <h4 className="fw-bold fs-3 p-2">
            Course ID: <span className="fw-light">{c.id}</span>{" "}
          </h4>
          <h4 className="fw-bold fs-3 p-2">
            {" "}
            Course Name: <br /> <span className="fw-light">{c.name}</span>{" "}
          </h4>
        </div>
      ))}
    </div>
  );
}

export default CourseDetails;
