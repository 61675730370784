import { useEffect, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import decode from "jwt-decode";

import AppRoutes from "./routes";
import { AuthContext } from "./AuthContext";

import "./App.css";
import authService from "./services/auth.service";

function App() {
  const [user, setUser] = useState(authService.getCurrentUser());
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const token = user.value[0].token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        setUser(null);
      }
    }
  }, [user]);

  return (
    <BrowserRouter>
      <AuthContext.Provider value={value}>
        <AppRoutes isLoggedIn={user} />
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
