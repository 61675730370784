import { useEffect, useState } from "react";
import questionsService from "../../services/questions.service";
import coursesService from "../../services/courses.service";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Pagination";
// import "./desc.css";

function ShowQuestions() {
  const [allQuestions, setAllQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [maxPage, setMaxPage] = useState(null);
  const [page, setPage] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const navigate = useNavigate();

  const getSelcted = (id) => {
    let newselected = selected;
    newselected = id;
    setSelected(newselected);
  };

  function Data() {
    const result = allQuestions.filter(
      (question) => question.course_id == selected,
    );
    // console.log(result);
    return (
      <div>
        {result?.map((question) => (
          <div className="course" key={question.id}>
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-7">
                  <h5>{question.content}</h5>
                </div>
                <div
                  className="
                    col-md-5 d-flex
                    flex-column
                    justify-content-center"
                >
                  <div className="row">
                    <div className="col-md-6 my-1">
                      <button
                        onClick={() => {
                          navigate(`/questions/showdetails/${question.id}`);
                        }}
                        className="btn w-100 btn-primary"
                      >
                        Show Details
                      </button>
                    </div>
                    <div className="col-md-3 my-1">
                      <button
                        onClick={() => {
                          navigate(`/questions/updatequestion/${question.id}`);
                        }}
                        className="btn w-100 btn-warning"
                      >
                        Update
                      </button>
                    </div>
                    <div className="col-md-3 my-1">
                      <button
                        onClick={() => {
                          navigate(`/questions/deletequestion/${question.id}`);
                        }}
                        className="btn w-100 btn-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  useEffect(() => {
    const AllData = async () => {
      let { data } = await questionsService.AllData();
      setLoading(true);
      if (data) {
        setError(false);
        setAllQuestions(data.value);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    const getAllQuestions = async () => {
      let { data } = await questionsService.getAll(page);
      setLoading(true);
      if (data) {
        setError(false);
        setQuestions(data.value);
        setMaxPage(data.maxPage);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    const getAllCourses = async () => {
      let { data } = await coursesService.AllData();
      setLoading(true);
      if (data) {
        setError(false);
        setCourses(data.value);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    AllData();
    getAllQuestions();
    getAllCourses();
  }, [page]);

  useEffect(() => {
    if (page === maxPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [maxPage, page]);

  return (
    <div className="courses">
      <div>
        {loading ? (
          <p className="text-center p-5 fs-5 text-info">Loading...</p>
        ) : (
          <div>
            {error ? (
              <p className="text-center p-5 fs-5 text-danger">
                something is wrong
              </p>
            ) : (
              <div>
                <div>
                  <label className="fs-4 fw-normal p-3" htmlFor="courses">
                    Choose a Course:{" "}
                  </label>
                  <select
                    onChange={(e) => getSelcted(e.target.value)}
                    className="w-25 p-3"
                    id="courses"
                  >
                    <option value="all">All</option>
                    {courses?.map((course, i) => (
                      <option key={i} value={course.id}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </div>
                <aside>
                  {selected ? (
                    <div>
                      {selected == "all" ? (
                        <div>
                          {questions?.map((question) => (
                            <div className="course" key={question.id}>
                              <div className="container">
                                <div className="row justify-content-between">
                                  <div className="col-md-7">
                                    <h5>{question.content}</h5>
                                  </div>
                                  <div
                                    className="
                                      col-md-5 d-flex
                                      flex-column
                                      justify-content-center"
                                  >
                                    <div className="row">
                                      <div className="col-md-6 my-1">
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/questions/showdetails/${question.id}`,
                                            );
                                          }}
                                          className="btn w-100 btn-primary"
                                        >
                                          Show Details
                                        </button>
                                      </div>
                                      <div className="col-md-3 my-1">
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/questions/updatequestion/${question.id}`,
                                            );
                                          }}
                                          className="btn w-100 btn-warning"
                                        >
                                          Update
                                        </button>
                                      </div>
                                      <div className="col-md-3 my-1">
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/questions/deletequestion/${question.id}`,
                                            );
                                          }}
                                          className="btn w-100 btn-danger"
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <Pagination
                            page={page}
                            setPage={setPage}
                            maxPage={maxPage}
                            nextDisabled={nextDisabled}
                          />
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <Data />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {questions?.map((question) => (
                        <div className="course" key={question.id}>
                          <div className="container">
                            <div className="row justify-content-between">
                              <div className="col-md-7">
                                <h5>{question.content}</h5>
                              </div>
                              <div
                                className="
                                  col-md-5 d-flex
                                  flex-column
                                  justify-content-center"
                              >
                                <div className="row">
                                  <div className="col-md-6 my-1">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/questions/showdetails/${question.id}`,
                                        );
                                      }}
                                      className="btn w-100 btn-primary"
                                    >
                                      Show Details
                                    </button>
                                  </div>
                                  <div className="col-md-3 my-1">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/questions/updatequestion/${question.id}`,
                                        );
                                      }}
                                      className="btn w-100 btn-warning"
                                    >
                                      Update
                                    </button>
                                  </div>
                                  <div className="col-md-3 my-1">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/questions/deletequestion/${question.id}`,
                                        );
                                      }}
                                      className="btn w-100 btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <Pagination
                        page={page}
                        setPage={setPage}
                        maxPage={maxPage}
                        nextDisabled={nextDisabled}
                      />
                    </div>
                  )}
                </aside>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ShowQuestions;
