import { NavLink, Outlet } from "react-router-dom";
import "./questions.css";

function Questions() {
  return (
    <div>
      <div className="courses-nav">
        <NavLink to="/questions/showquestions">Show Questions </NavLink>
        <NavLink to="/questions/addquestions">Add Question </NavLink>
      </div>
      <Outlet />
    </div>
  );
}

export default Questions;
