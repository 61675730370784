import { useState } from "react";
import { useNavigate } from "react-router-dom";
import coursesService from "../../services/courses.service";

// import "./desc.css";

function AddCourses() {
  const [addCourse, setAddCourse] = useState({
    name: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  function getNewCourse(e) {
    let newaddCourse = { ...addCourse };
    newaddCourse[e.target.name] = e.target.value;
    setAddCourse(newaddCourse);
  }

  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    const { data } = await coursesService.createOne(addCourse);
    if (data) {
      setError(false);
      navigate("/courses/showcourses");
    } else {
      setError(true);
    }
    setLoading(false);
  }

  return (
    <div className="courses">
      <aside>
        <header>
          <form onSubmit={submitForm} className="add-new-course">
            <label>Course Name </label>
            <input onChange={getNewCourse} type="text" name="name" />
            <button type="submit" className="new">
              {loading ? "Loading..." : " Add New Course"}
            </button>
          </form>
        </header>
      </aside>
      <div className="text-center p-3">
        {error ? <p className="fs-5 text-danger">something is wrong</p> : ""}
      </div>
    </div>
  );
}

export default AddCourses;
