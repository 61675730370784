import { Navigate, useRoutes } from "react-router-dom";

import Dashboard from "./Dashboard";
import Login from "./Login";
import Exam from "./Exam";

const AppRoutes = ({ isLoggedIn }) => {
  const routes = useRoutes([
    {
      path: "/login",
      element: !isLoggedIn ? <Login /> : <Navigate to="/" />,
    },
    {
      path: "/exam",
      element: <Exam />,
    },
    {
      path: "*",
      element: isLoggedIn ? <Dashboard /> : <Navigate to="/login" />,
    },
  ]);

  return routes;
};

export default AppRoutes;
