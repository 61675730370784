import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

import authService from "../services/auth.service";

import "./Login.css";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ username: "", password: "" });
  const { setUser } = useContext(AuthContext);

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const res = await authService.login(formData);
    if (res.status === 200) {
      setUser(res.data);
      navigate("/", { replace: true });
    }

    setLoading(false);
  }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const selectOnFocus = (e) => e.target.select();

  return (
    <div className="login">
      <div className="container">
        <form onSubmit={onSubmit}>
          <div className="login-header">
            <label>Login</label>
          </div>

          <input
            name="username"
            type="text"
            placeholder="Username"
            autoComplete="off"
            required
            onFocus={selectOnFocus}
            onChange={onChange}
          />
          <input
            name="password"
            type="password"
            placeholder="Password"
            autoComplete="off"
            required
            onFocus={selectOnFocus}
            onChange={onChange}
          />

          <button type="submit">{loading ? "Loading..." : "Login"}</button>
        </form>
      </div>
    </div>
  );
}
