import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import answersService from "../../services/answers.service";
import questionsService from "../../services/questions.service";

function UpdateAnswer() {
  const [updateAnswer, setUpdateAnswer] = useState({
    content: "",
    isCorect: "",
    questions_id: "",
  });
  const [answer, setAnswer] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selected, setSelected] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const getSelcted = (id) => {
    let newselected = selected;
    newselected = id;
    setSelected(newselected);
  };

  function getUpdateAnswer(e) {
    let newUpdateAnswer = { ...updateAnswer };
    newUpdateAnswer[e.target.name] = e.target.value;
    setUpdateAnswer(newUpdateAnswer);
    getSelcted(e.target.value);
  }

  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    const { data } = await answersService.updateOne(updateAnswer, id);
    if (data) {
      setError(false);
      navigate("/answers/showanswers");
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const getAllQuestions = async (callback) => {
    let { data } = await questionsService.AllData();
    setLoading(true);
    if (data) {
      setError(false);
      callback(data.value);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllQuestions(setQuestions);
  }, []);

  useEffect(() => {
    const getOneAnswerByID = async () => {
      let { data } = await answersService.getOneByID(id);
      setLoading(true);
      if (data) {
        setLoading(false);
        setAnswer(data.value);
      }
      setLoading(false);
    };
    getOneAnswerByID();
  }, [id]);

  return (
    <div className="courses">
      <aside>
        <header>
          <form onSubmit={submitForm} className="add-new-course">
            <label>Answer Content </label>
            <input onChange={getUpdateAnswer} type="text" name="content" />
            {loading ? (
              <p className="p-2 fs-5 text-start text-primary">Loading...</p>
            ) : (
              <div>
                {answer?.map((ans, i) => (
                  <p key={i} className="p-2 fs-5 text-start text-primary">
                    previous answer content : <br />
                    <span className="text-dark">{ans.content}</span>
                  </p>
                ))}
              </div>
            )}
            <label>Answer Value</label>
            <select
              className="w-100 p-2"
              onChange={getUpdateAnswer}
              name="isCorect"
            >
              <option value="Choose Answer Value:">Choose Answer Value:</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="12">12</option>
            </select>
            {loading ? (
              <p className="p-2 fs-5 text-start text-primary">Loading...</p>
            ) : (
              <div>
                {answer?.map((ans, i) => (
                  <p key={i} className="p-2 fs-5 text-start text-primary">
                    previous answer Value : <br />
                    <span className="text-dark">
                      {ans.isCorect}
                    </span>
                  </p>
                ))}
              </div>
            )}
            <label>questions_id</label>
            <select
              onChange={getUpdateAnswer}
              name="questions_id"
              className="w-100 p-2"
            >
              <option value="Select Questions ID:">Select Questions ID:</option>
              {questions?.map((question, i) => (
                <option key={i} value={question.id}>
                  {question.id}
                </option>
              ))}
            </select>
            {loading ? (
              <p className="p-2 fs-5 text-start text-primary">Loading...</p>
            ) : (
              <div>
                {answer?.map((ans, i) => (
                  <p key={i} className="p-2 fs-5 text-start text-primary">
                    previous answer questions_id : <br />
                    <span className="text-dark">{ans.questions_id}</span>
                  </p>
                ))}
              </div>
            )}
            <button type="submit" className="new">
              {loading ? "Loading..." : "Update Answer"}
            </button>
          </form>
        </header>
      </aside>
      <div className="text-center p-3">
        {error ? <p className="fs-5 text-danger">something is wrong</p> : ""}
      </div>
    </div>
  );
}

export default UpdateAnswer;
