import axios from "axios";

import authHeader from "./auth-header";

const API_URL = "https://americanacademyeg.co/api/v1/";
// const API_URL = "http://localhost:5000/api/v1/";

class CourseService {
  async getAll(page) {
    try {
      const res = await axios.get(`${API_URL + "courses"}`, {
        headers: authHeader(),
        params: { page },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async AllData() {
    try {
      const res = await axios.get(`${API_URL + "allcourses"}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async createOne(name) {
    try {
      const res = await axios.post(`${API_URL + "course"}`, name, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async getOneByID(id) {
    try {
      const res = await axios.get(`${API_URL}/course/${id}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async deleteOne(id) {
    try {
      const res = await axios.delete(`${API_URL}/course/${id}`, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  async updateOne(course, id) {
    try {
      const res = await axios.put(`${API_URL}/course/${id}`, course, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  }
}

export default new CourseService();
