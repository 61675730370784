import { useState } from "react";
import { add } from "date-fns";

import examService from "../services/exam.service";
import LiveExam from "./LiveExam";

import "./Exam.css";

export default function Exam() {
  const [data, setData] = useState(null);
  const [id, setID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const futureDate = add(new Date(), {
    minutes: data?.exam.time,
  });

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const res = await examService.get(id);
    if (res.status === 200) {
      setData(res.data);
    } else {
      setError(res.response.data.ERROR);
    }

    setLoading(false);
  }

  const onChange = (e) => {
    setID(e.target.value);
    setError(null);
  };

  return (
    <div className="exam">
      {data ? (
        <LiveExam
          futureDate={futureDate}
          questions={data.questions}
          exam={data.exam}
          id={id}
        />
      ) : (
        <div className="containerExam container-fluid">
          <p className="fs-5 fw-bold">Enter Your Exam Code</p>
          <div className="containerForm">
            <form onSubmit={onSubmit}>
                <div>
                <input
                  name="id"
                  type="text"
                  placeholder="Exam code"
                  autoComplete="off"
                  required
                  onChange={onChange}
                />
                <button type="submit">
                  {loading ? "Loading..." : "Start"}
                </button>
              </div>
              {error && (
                <div>
                  <label> {error} </label>
                </div>
              )}
            </form>
          </div>
            <p className="text-center fs-5">
              <span className="text-muted">or</span>
              <br />
              <span className="text-danger">
                To Register an Exam Please Contact Our Customer Service on below Numbers
              </span>
              <br />
              <span className="fw-bold">0238371018-01099991865</span>
            </p>  
        </div>
      )}
    </div>
  );
}
