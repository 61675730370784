import { useEffect, useState } from "react";
import Pagination from "../Pagination";

import studentsService from "../services/students.service";

import "./Students.css";

export default function Students() {
  const [students, setStudents] = useState(null);
  const [maxPage, setMaxPage] = useState(null);
  const [page, setPage] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);

  useEffect(() => {
    const getAll = async () => {
      const res = await studentsService.getAll(page);
      // console.log(res.data);
      setStudents(res.data.value);
      setMaxPage(res.data.maxPage);
    };

    getAll();
  }, [page]);

  useEffect(() => {
    if (page === maxPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [maxPage, page]);

  return (
    <div className="container">
      <div className="con">
        <table className="w-100 text-center students">
          <thead>
            <tr>
              <th>Invoice ID</th>
              <th>Name</th>
              <th>Score</th>
              <th>Questions Number</th>
            </tr>
          </thead>
          {students?.map((student, i) => (
            <tbody key={i}>
              <tr>
                <td>{student.invoice_id}</td>
                <td>{student.name}</td>
                <td>
                  {student.score}
                </td>
                <td>
                  {student.questions_number}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        nextDisabled={nextDisabled}
      />
    </div>
  );
}
