import { useEffect, useState } from "react";
import coursesService from "../../services/courses.service";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Pagination";
// import "./desc.css";

function ShowCourses() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [maxPage, setMaxPage] = useState(null);
  const [page, setPage] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllCourses = async () => {
      let { data } = await coursesService.getAll(page);
      setLoading(true);
      if (data) {
        setError(false);
        setCourses(data.value);
        setMaxPage(data.maxPage);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    getAllCourses();
  }, [page]);

  useEffect(() => {
    if (page === maxPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [maxPage, page]);

  return (
    <div className="courses">
      <div>
        {loading ? (
          <p className="text-center p-5 fs-5 text-info">Loading...</p>
        ) : (
          <div>
            {error ? (
              <p className="text-center p-5 fs-5 text-danger">
                something is wrong
              </p>
            ) : (
              <aside>
                {courses?.map((course) => (
                  <div className="course" key={course.id}>
                    <div className="container">
                      <div className="row justify-content-between">
                        <div className="col-md-7">
                          <h5>{course.name}</h5>
                        </div>
                        <div
                          className="
                    col-md-5 d-flex
                    flex-column
                    justify-content-center"
                        >
                          <div className="row">
                            <div className="col-md-6 my-1">
                              <button
                                onClick={() => {
                                  navigate(`/courses/showdetails/${course.id}`);
                                }}
                                className="btn w-100 btn-primary"
                              >
                                Show Details
                              </button>
                            </div>
                            <div className="col-md-3 my-1">
                              <button
                                onClick={() => {
                                  navigate(
                                    `/courses/updatecourse/${course.id}`,
                                  );
                                }}
                                className="btn w-100 btn-warning"
                              >
                                Update
                              </button>
                            </div>
                            <div className="col-md-3 my-1">
                              <button
                                onClick={() => {
                                  navigate(
                                    `/courses/deletecourse/${course.id}`,
                                  );
                                }}
                                className="btn w-100 btn-danger"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </aside>
            )}
          </div>
        )}
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        nextDisabled={nextDisabled}
      />
    </div>
  );
}

export default ShowCourses;
