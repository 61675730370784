import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import coursesService from "../../services/courses.service";
import questionsService from "../../services/questions.service";
// import "./desc.css"

function AddQuestion() {
  const [addQuestions, setAddQuestions] = useState({
    content: "",
    course_id: "",
  });
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getSelcted = (id) => {
    let newselected = selected;
    newselected = id;
    setSelected(newselected);
  };

  function getNewQuestion(e) {
    let newaddQuestions = { ...addQuestions };
    newaddQuestions[e.target.name] = e.target.value;
    setAddQuestions(newaddQuestions);
    getSelcted(e.target.value);
  }

  const getAllCourses = async (callback) => {
    let { data } = await coursesService.AllData();
    setLoading(true);
    if (data) {
      setError(false);
      callback(data.value);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    const { data } = await questionsService.createOne(addQuestions);
    if (data) {
      setError(false);
      navigate("/questions/showquestions");
    } else {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    getAllCourses(setCourses);
  }, []);

  return (
    <div className="courses">
      <aside>
        <header>
          <form onSubmit={submitForm} className="add-new-course">
            <label>Question Content </label>
            <input onChange={getNewQuestion} type="text" name="content" />
            <label>Course</label>
            <select
              onChange={getNewQuestion}
              name="course_id"
              className="w-100 p-2"
            >
              <option value="Select Course:">Select Course:</option>
              {courses?.map((course, i) => (
                <option key={i} value={course.id}>
                  {course.name}
                </option>
              ))}
            </select>
            <button type="submit" className="new">
              {loading ? "Loading..." : " Add New Question"}
            </button>
          </form>
        </header>
      </aside>
      <div className="text-center p-3">
        {error ? <p className="fs-5 text-danger">something is wrong</p> : ""}
      </div>
    </div>
  );
}

export default AddQuestion;
