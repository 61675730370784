import { useEffect, useState } from "react";

import coursesService from "../services/courses.service";
import examService from "../services/exam.service";

import "./Home.css";

export default function Home() {
  const [courses, setCourses] = useState([]);
  const [formData, setFormData] = useState(null);
  const [code, setCode] = useState(null);

  useEffect(() => {
    const getAllCourses = async () => {
      const res = await coursesService.AllData();
      setCourses(res.data.value);
    };
    getAllCourses();
  }, []);

  async function onSubmit(e) {
    e.preventDefault();
    const data = formData;
    data.expires_in = "1h";

    const res = await examService.generate(data);
    // console.log(res);
    if (res.status === 201) {
      setCode(res.data.id);
      // console.log(res.data.id);
    }
  }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <div className="home">
      <div className="home-continer">
        <div className="gen">
          <p>
            Code: <span className="code">{code}</span>
          </p>
          <form onSubmit={onSubmit}>
            <select name="course_id" onChange={onChange}>
              <option></option>
              {courses?.map((course) => (
                <option value={course.id} key={course.id}>
                  {course.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              name="time"
              required
              onChange={onChange}
              placeholder="time by mintes"
            />
            <input
              type="number"
              name="questions_limit"
              required
              onChange={onChange}
              placeholder="number of questions"
            />
            <button type="submit">Generate</button>
          </form>
        </div>
      </div>
    </div>
  );
}
